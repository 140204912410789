







import Component from 'vue-class-component'
import variables from '@/styles/_export.module.scss'
import Vue from 'vue'

@Component
export default class DisabledLinkIcon extends Vue {
    iconColor = variables.navy100
}
