







import Component from 'vue-class-component'
import variables from '@/styles/_export.module.scss'
import Vue from 'vue'

@Component
export default class LinkIcon extends Vue {
    linkColor = variables.navy500
}
