import router from '@/router/router'
import store from '@/store'
import {CyberRiskError} from '@/models/CyberRiskError'
import {NistFunction} from '@/models/NistFunction'
import {User} from '@/models/User'

function clearErrors(): void {
    const cyberRiskError = store.getters.getCyberRiskError
    if (cyberRiskError.type !== 'warning') {
        store.commit('setCyberRiskError', new CyberRiskError())
    }
}

function handleError(err: CyberRiskError): void {
    const type = err.type
    if (type) {
        switch (type) {
            case 'app':
                handleAppError(err)
                break
            case 'critical':
                handleCriticalError(err)
                break
            case 'scorecard':
                handleScorecardError(err)
                break
            case 'user':
                handleUserError(err)
                break
            case 'warning':
                handleWarningError(err)
                break
        }
    }
}

function handleErrorsOnFunctionMount(err: CyberRiskError): void {
    if (err && err.type === 'warning') {
        console.error(err)
        err.type = 'app'
        store.commit('setCyberRiskError', err)
    }
}

function handleCriticalError(err: CyberRiskError): void {
    console.error(err)
    store.commit('setCyberRiskError', err)
    router.push({name: 'ErrorPage'})
}

function handleAppError(err: CyberRiskError): void {
    console.error(err)
    store.commit('setCyberRiskError', err)
}

function handleScorecardError(err: CyberRiskError): void {
    console.error(err)
    store.commit('setCyberRiskError', err)
    router.push({name: 'ErrorPage'})
}

function handleUserError(err: CyberRiskError): void {
    console.error(err)
    store.commit('setCyberRiskError', err)
    store.commit('setCurrentUser', new User())

    if (err.statusCode == 401) {
        router.push({name: 'AccessDenied'})
    } else {
        router.push({name: 'ErrorPage'})
    }
}

function handleWarningError(err: CyberRiskError): void {
    const currentRouteName = router.currentRoute.name
    const functionRoutePrefix = 'Roadmap'
    const currentFunctionName = currentRouteName?.substring(functionRoutePrefix.length)
    const nistFunctions = store.getters.getCachedNistFunctions
    const foundFunction = nistFunctions.filter((f: NistFunction) => f.name === currentFunctionName)[0]

    if (foundFunction || currentRouteName === 'RoadmapWelcome') {
        err.type = 'app'
    }

    console.error(err)
    store.commit('setCyberRiskError', err)
}

export {clearErrors, handleError, handleErrorsOnFunctionMount}