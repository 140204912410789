import Vue from 'vue'
import Vuex from 'vuex'
import appConfig from '@/store/modules/appConfig'
import assessment from '@/store/modules/assessment'
import exception from '@/store/modules/exception'
import user from '@/store/modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        appConfig,
        assessment,
        exception,
        user
    }
})
