
















import Component from 'vue-class-component'
import {Prop, Vue, Watch} from 'vue-property-decorator'

@Component
export default class PreviewModal extends Vue {

    @Prop()
    isOpen!: boolean

    @Prop()
    previewImage!: string

    @Prop()
    productType!: string

    showDialog = false

    closeDialog() :void {
        this.$emit('togglePreview', this.productType)
    }

    @Watch('isOpen')
    onPropertyValueChanged(val:boolean): void {
        this.showDialog = val
    }
}
