





















import Vue from 'vue'
import Component from 'vue-class-component'
import variables from '@/styles/_export.module.scss'

@Component
export default class OverviewIcon extends Vue {
    get color(): string {
        return this.$route.name === 'RoadmapOverview' ? variables.navy500 : variables.navy100
    }
}
