import {AssessmentAnswer} from '@/models/AssessmentAnswer'
import {PolicyData} from '@/models/PolicyData'

export class Assessment {
    answers!: AssessmentAnswer[]
    createdByUsername!: string
    createdDateTime!: string
    id!: string
    industry!: string
    lastUpdatedByUsername!: string
    lastUpdatedDateTime!: string
    policyData!: PolicyData[]
}