import axios, { AxiosInstance } from 'axios'

let CSRF_TOKEN: string

if (document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`)) != null) {
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    CSRF_TOKEN = document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`))[1]

} else {
    CSRF_TOKEN = ''
}

const axiosInstance: AxiosInstance = axios.create({
    timeout: 60000,
    headers: {
        "X-XSRF-TOKEN": CSRF_TOKEN,
        'Content-Type': 'application/json;charset=UTF-8',
    }
})

export default axiosInstance