import {DateTime} from 'luxon'

export class DateUtils {
    getLastVisitedDateString(previousDateString: string): string {
        const previousDate = DateTime.fromFormat(previousDateString, 'yyyy-MM-dd')
        let result = ''
        const today = DateTime.local()
        const difference = today.diff(previousDate, ['years', 'months', 'days', 'hours'])
        const years = difference.years
        const months = difference.months
        const days = difference.days

        if (years >= 2 || years == 1 && months < 1) {
            result = years + (years == 1 ? ' year' : ' years') + ' ago'
        } else if (months > 0 && years > 0) {
            result = years + ' year, ' + months + ' months ago'
        } else if (months > 0 && days == 0) {
            result = months + (months == 1 ? ' month' : ' months') + ' ago'
        } else if (days > 0 && months > 0) {
            result = months
                + (months == 1 ? ' month' : ' months') + ', '
                + days + (days == 1 ? ' day' : ' days') + ' ago'
        } else if (days > 0) {
            result = days + (days == 1 ? ' day' : ' days') + ' ago'
        } else {
            result = 'today'
        }

        return result
    }
}