import ProductOffering from '@/models/ProductOffering'
import productOfferings from '../../config/product-offering-content.json'
import { Industry } from '@/models/Industry'
import { NistFunction } from '@/models/NistFunction'
import { Recommendation } from '@/models/Recommendation'
import {AppConfig} from '@/models/AppConfig'

interface IAppState {
    appConfig: AppConfig,
    assessmentLoading: boolean,
    cachedNistFunctions: NistFunction[],
    currentCategoryId: number | null,
    eriskAccessCode: string,
    functionsLoading: boolean,
    industries: Industry[],
    personalChecklistURL: string,
    productOfferings: ProductOffering[],
    recommendations: Recommendation[],
    recommendationsLoading: boolean,
    requiredDataLoading: boolean
}

const state: IAppState = {
    appConfig: new AppConfig(),
    assessmentLoading: false,
    cachedNistFunctions: [],
    currentCategoryId: null,
    eriskAccessCode: '',
    functionsLoading: false,
    industries: [],
    personalChecklistURL: '',
    productOfferings: productOfferings as ProductOffering[],
    recommendations: [],
    recommendationsLoading: false,
    requiredDataLoading: false
}

const getters = {
    getAppConfig: (state: IAppState): AppConfig => {
        return state.appConfig
    },
    getAssessmentLoading: (state: IAppState): boolean => {
        return state.assessmentLoading
    },
    getCachedNistFunctions: (state: IAppState): NistFunction[] => {
        return state.cachedNistFunctions
    },
    getCurrentCategoryId: (state: IAppState): number | null => {
        return state.currentCategoryId
    },
    getEriskAccessCode: (state: IAppState): string => {
        return state.eriskAccessCode
    },
    getIndustries: (state: IAppState): Industry[] => {
        return state.industries
    },
    getRecommendations: (state: IAppState): Recommendation[] => {
        return state.recommendations
    },
    getPersonalChecklistURL: (state: IAppState): string => {
        return state.personalChecklistURL
    },
    getProductOfferings: (state: IAppState): ProductOffering[] => {
        return state.productOfferings
    },
    getRequiredDataLoading: (state: IAppState): boolean => {
        return state.requiredDataLoading
    },
    getRecommendationsLoading: (state: IAppState): boolean => {
        return state.recommendationsLoading
    },
    getFunctionsLoading: (state: IAppState): boolean => {
        return state.functionsLoading
    }
}
const mutations = {
    setAppConfig(state: IAppState, params: AppConfig): void {
        state.appConfig = params
    },
    setAssessmentLoading(state: IAppState, params: boolean): void {
        state.assessmentLoading = params
    },
    setCachedNistFunctions(state: IAppState, params: NistFunction[]): void {
        state.cachedNistFunctions = params
    },
    setCurrentCategoryId(state: IAppState, params: number): void {
        state.currentCategoryId = params
    },
    setEriskAccessCode(state: IAppState, params: string): void {
        state.eriskAccessCode = params
    },
    setIndustries(state: IAppState, params: Industry[]): void {
        state.industries = params
    },
    setRecommendations(state: IAppState, params: Recommendation[]): void {
        state.recommendations = params
    },
    setPersonalChecklistUrl(state: IAppState, params: string): void {
        state.personalChecklistURL = params
    },
    // Shouldn't ever be used?
    setProductOfferings(state: IAppState, params: ProductOffering[]): void {
        state.productOfferings = params
    },
    setRequiredDataLoading(state: IAppState, params: boolean): void {
        state.requiredDataLoading = params
    },
    setRecommendationsLoading(state: IAppState, params: boolean): void {
        state.recommendationsLoading = params
    },
    setFunctionsLoading(state: IAppState, params: boolean): void {
        state.functionsLoading = params
    }
}

export default {
    state,
    getters,
    mutations
}