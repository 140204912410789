












import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class NextFunctionNavIcon extends Vue {
    get hasNext() :boolean {
        const identify = 'RoadmapIdentify'
        const protect = 'RoadmapProtect'
        const detect = 'RoadmapDetect'
        const respond = 'RoadmapRespond'
        const recover = 'RoadmapRecover'

        const currentRoute = this.$router.currentRoute
        const routeNames = [identify, protect, detect, respond, recover]
        let hasNext = false
        for (let i = 0; i < routeNames.length; i++) {
            const routeName = routeNames[i]
            if (routeName === currentRoute.name) {
                hasNext = !!routeNames[i + 1]
                break
            }
        }
        return hasNext

    }
}
