import '@/styles/global.scss'
import App from './App.vue'
import IdleVue from 'idle-vue'
import Vue from 'vue'
import router from '@/router/router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.use(VueGtag, { config: { id: 'G-4ZMB9R5VPZ' }}, router);

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

/*
  Session Idle timeout set to 28min
  Timeout modal appears with 2min remaining
  Oauth timeout is 30min
*/
Vue.use(
    IdleVue, {eventEmitter: app, store, idleTime: 1680000, startAtIdle: false}
)

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
globalThis.regeneratorRuntime = undefined

