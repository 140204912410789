import axiosInstance from '@/utils/axios-instance'
import {AppConfig} from '@/models/AppConfig'


function getAppConfig(): Promise<AppConfig> {
    let config!: AppConfig
    return axiosInstance.get('/api/config')
        .then(response => {
            config = response.data
            return config
        })
        .catch(error => {
            console.log(error)
            return config
        })
}

export {
    getAppConfig
}
