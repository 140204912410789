












































































































import ClockIcon from '@/components/icons/ClockIcon.vue'
import Component from 'vue-class-component'
import PreviewModal from '@/components/PreviewModal.vue'
import ProductOfferingMobile from '@/components/ProductOfferingMobile.vue'
import ProductOfferingModel from '@/models/ProductOffering'
import ProductOfferingThreeWide from '@/components/ProductOfferingThreeWide.vue'

import Vue from 'vue'
import store from '@/store'

@Component({
  components: {ProductOfferingMobile, ClockIcon, PreviewModal, ProductOfferingThreeWide}
})
export default class LandingPage extends Vue {

  currentPreviewImage = ''
  currentProductType = ''
  isPreviewOpen = false
  productOfferings = store.getters.getProductOfferings as ProductOfferingModel[]
  subtitle1 = 'Great American knows protection. We offer the same tools we use to protect ourselves to protect our policyholders. When you’re secure, we’re secure.'
  subtitle2 = 'Our tools, based on the Five Functions of NIST’s Cybersecurity Framework, find strengths and weaknesses in your cyber defenses.'
  subtitle3 = 'Note: The questions in the Security Roadmap are not part of your application for insurance and your answers will not affect your policy\'s terms and conditions.'
  title1 = 'Find your cybersecurity vulnerability first'
  title2 = 'Avoid costly losses of data, access, and reputation'
  title3 = 'Included with the purchase of a great american cyber risk policy'

  togglePreview(type: string): void {
    const currentProduct = this.productOfferings.filter(p => p.productType === type)[0]
    this.currentProductType = type
    this.currentPreviewImage = currentProduct.previewImage

    this.isPreviewOpen = !this.isPreviewOpen
  }

  get isMobile(): boolean {
      const breakpoint = this.$vuetify.breakpoint
      return breakpoint.width < 767
  }
}
