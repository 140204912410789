

































import Component from 'vue-class-component'
import store from '@/store'
import variables from '@/styles/_export.module.scss'
import {Prop, Vue} from 'vue-property-decorator'
import {UserPreferences} from '@/models/UserPreferences'

@Component
export default class ProductOfferingNistScore extends Vue {

  @Prop()
  hasSmallTileSize!: boolean

  @Prop()
  getStartedRoute!: string

  get userPreferences(): UserPreferences {
    return store.getters.getUserPreferences
  }

  tileColor = ''
  scoreTextColor = ''

  get nistMaturityScore(): number {
    return this.userPreferences ? this.userPreferences.nistMaturityScore : 0.0
  }

  navToPage(): void {
    this.$router.push(this.getStartedRoute)
  }

  mounted(): void {
    const score = this.nistMaturityScore
    if (!score || score < 0.5) {
      this.tileColor = variables.red700
      this.scoreTextColor = 'text-white'
    } else if (score > 0.49 && score < 1.0) {
      this.tileColor = variables.red600
      this.scoreTextColor = 'text-white'
    } else if (score > .99 && score < 2.0) {
      this.tileColor = variables.orange
      this.scoreTextColor = 'text-brown'
    } else if (score > 1.9 && score < 2.5) {
      this.tileColor = variables.gold300
      this.scoreTextColor = 'text-brown'
    } else if (score > 2.49 && score < 3.0) {
      this.tileColor = variables.shamrock300
      this.scoreTextColor = 'text-green'
    } else if (score > 2.99 && score < 4.0) {
      this.tileColor = variables.shamrock500
      this.scoreTextColor = 'text-white'
    } else {
      this.tileColor = variables.shamrock700
      this.scoreTextColor = 'text-white'
    }
  }
}
