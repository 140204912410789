






import Vue from 'vue'
import Component from 'vue-class-component'
import variables from '@/styles/_export.module.scss'

@Component
export default class NextCategoryIcon extends Vue {
    get iconColor(): string {
        return variables.white
    }
}
