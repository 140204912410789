






































































































































































import Component from 'vue-class-component'
import ProductOfferingNistScore from '@/components/ProductOfferingNistScore.vue'
import store from '@/store'
import {DateUtils} from '@/utils/date-utils'
import {Prop, Vue} from 'vue-property-decorator'
import {UserPreferences} from '@/models/UserPreferences'

@Component({
  components: {ProductOfferingNistScore}
})
export default class ProductOfferingThreeWide extends Vue {

  @Prop()
  altImageText!: string

  @Prop()
  dataCyPrefix!: string

  @Prop()
  duration!: string

  @Prop()
  image!: string

  @Prop()
  footerText!: string

  @Prop()
  footerSubtext!: string

  @Prop()
  getStartedRoute!: string

  @Prop()
  historyTextPrefix!: string

  @Prop()
  title!: string

  @Prop()
  productType!: string

  dateUtils = new DateUtils()
  renderKey = 0

  get showButtonRowDurationSection(): boolean {
    if (this.productType !== 'assessment') {
      return !!this.historyText
    }

    return !!this.userPreferences.nistMaturityScore
  }

  get historyText(): string {
    let previousDate = ''
    let text = ''
    switch (this.productType) {
      case 'insights':
        previousDate = this.userPreferences.lastViewedInsights
        break
      case 'scorecard':
        previousDate = this.userPreferences.lastViewedScorecard
        break
      case 'assessment':
        previousDate = this.userPreferences.lastUpdatedAssessment
        break
    }

    if (previousDate) {
      text = this.historyTextPrefix + this.dateUtils.getLastVisitedDateString(previousDate)
    }

    return text
  }

  get idPrefix(): string {
    return this.dataCyPrefix + this.productType
  }

  get showCompanyScore(): boolean {
    if (!this.userPreferences.companyScore || !this.historyText) {
      return false
    }

    return this.userPreferences.companyScore.grade != null && this.productType === 'scorecard'
  }

  get showHeaderDuration(): boolean {
    return !this.historyText || (this.productType === 'assessment' && !this.userPreferences.nistMaturityScore)
  }

  get hasCompletedAssessment(): boolean {
    if (this.productType !== 'assessment')
      return false

    return !!this.userPreferences.nistMaturityScore
  }

  get showHeaderHistoryText(): boolean {
    return (!!this.historyText  && this.productType !== 'assessment') || this.hasCompletedAssessment
  }

  get showProductImage(): boolean {
    return !this.historyText || (this.productType === 'assessment' && !this.userPreferences.nistMaturityScore) || this.productType === 'insights'
  }

  get showProductImageSection(): boolean {
    return !this.showCompanyScore || (this.productType !== 'scorecard')
  }

  get showNistScore(): boolean {
    return !!this.historyText && this.productType === 'assessment' && !!this.userPreferences.nistMaturityScore
  }

  get startButtonText(): string {
    let text = ''
    switch (this.productType) {
      case 'insights':
        text = 'Review'
        break
      case 'scorecard':
        text = 'Reassess'
        break
      case 'assessment':
        text = this.userPreferences.nistMaturityScore ? 'Reassess' : 'Resume'
        break
    }
    return text
  }

  get userPreferences(): UserPreferences {
    return store.getters.getUserPreferences
  }

  navToPage(): void {
    this.$router.push(this.getStartedRoute)
  }

  togglePreview(): void {
    this.$emit('togglePreview', this.productType)
  }

  mounted(): void {
    this.renderKey++
  }
}
