









































































































































































































































































































import AppFooter from '@/components/AppFooter.vue'
import Component from 'vue-class-component'
import store from '@/store'
import variables from '@/styles/_export.module.scss'
import {AppConfig} from '@/models/AppConfig'
import {AppUtils} from '@/utils/app-utils'
import {Assessment} from '@/models/Assessment'
import {RouterUtils} from '@/utils/router-utils'
import {User} from '@/models/User'
import {Vue, Watch} from 'vue-property-decorator'
import {getAppConfig} from '@/service/ConfigService'
import {getPersonalChecklist} from '@/service/PDFService'
import {logout, getCurrentUser} from '@/service/UserService'

@Component({
  components: { AppFooter }
})
export default class App extends Vue {
  // eslint-disable-next-line
  countdownTimer!: any
  expirationDurationKey = 0
  isTimeoutOpen = false
  red600 = variables.red600
  registrationURL = ''
  routerUtils = new RouterUtils()
  sessionExpirationMinutes = 1
  sessionExpirationSeconds = 59
  showError = false
  timeoutDuration = 120
  toolMenu = false
  userMenu = false
  logoutURL!: string
  appUtils = new AppUtils()

  @Watch('hasAppError')
  onHasErrorChanged(val: boolean): void {
    this.showError = val
  }

  @Watch('timeoutDuration')
  onDurationChanged(val: number): void {
    if (val < 1) {
      this.logout()
      clearInterval(this.countdownTimer)
      this.isTimeoutOpen = false
      window.location.href = this.logoutURL
    }
  }

  get assessment(): Assessment {
    return store.getters.getAssessment
  }

  get assessmentRoute(): string {
    let route = ''
    if (this.assessment && this.assessment.answers) {
      route = this.assessment.answers.length > 0 ? 'RoadmapOverview' : 'LoadingPage'
    } else {
      route = 'LoadingPage'
    }

    return route
  }

  get currentUser(): User {
    return store.getters.getCurrentUser
  }

  get hasAppError(): boolean {
    return store.getters.getCyberRiskError !== null && store.getters.getCyberRiskError.type === 'app'
  }

  get isAuthenticated(): boolean {
    const authenticated = this.currentUser ? this.currentUser.authenticated : false
    return authenticated
  }

  get isAuthorized(): boolean {
    const authorized = this.currentUser ? this.currentUser.authorized : false
    return authorized
  }

  get isAuthorizedRoute(): boolean {
    return this.$route.name !== 'AccessDenied' && this.$route.name !== 'ErrorPage'
  }

  get isMobile(): boolean {
    const breakpoint = this.$vuetify.breakpoint
    return breakpoint.width < 767
  }

  get userName(): string {
    return this.currentUser ? this.currentUser.firstName + ' ' + this.currentUser.lastName : ''
  }

  get sessionTimeoutDurationMessage(): string {
    let minuteText = this.sessionExpirationMinutes ? this.sessionExpirationMinutes + ' minute' : ''
    let secondText = this.sessionExpirationSeconds > 1 || this.sessionExpirationSeconds == 0 ? ' seconds' : ' second'
    return minuteText  + ' ' + this.sessionExpirationSeconds + secondText
  }

  get showLogin(): boolean {
    const showLogin = !this.isAuthenticated && this.isAuthorizedRoute
    return showLogin
  }

  get showCreateAccount(): boolean {
    const showCreateAccount = !this.isAuthenticated && this.isAuthorizedRoute
    return showCreateAccount
  }

/*
  Session Idle timeout set to 28min
  Timeout modal appears with 2min remaining (this.$store.state.idleVue.isIdle)
  OAuth timeout is 30min
*/
  get showTimeOutModal(): boolean {
    if (this.isTimeoutOpen) {
        return true
    } else if (this.$store.state.idleVue?.isIdle && this.currentUser.authenticated) {
        this.startTimer()
        this.isTimeoutOpen = true
        return this.$store.state.idleVue?.isIdle
    }
    return false
  }

  get showTools(): boolean {
    const showTools = this.isAuthenticated && this.isAuthorized && this.isAuthorizedRoute
    return showTools
  }

  get showUserMenu(): boolean {
    const showUserMenu = this.isAuthenticated && this.isAuthorized && this.isAuthorizedRoute
    return showUserMenu
  }

  startTimer(): void {
    this.countdownTimer = setInterval(() => {
      if (this.timeoutDuration > 0) {
        --this.timeoutDuration
        this.sessionExpirationMinutes = Math.floor(this.timeoutDuration/60)
        this.sessionExpirationSeconds = Math.floor(this.timeoutDuration % 60)
        ++this.expirationDurationKey
      }
    }, 1000)
  }

  get tools(): { id: string, route: { name: string }, title: string }[] {
    return [
      {id: 'insights', route: {name: 'EmployeeInsights'}, title: 'Employee Insights'},
      {id: 'scan', route: {name: 'Scorecard'}, title: 'Security Scorecard'},
      {id: 'assessment', route: {name: this.assessmentRoute}, title: 'Security Roadmap'}
    ]
  }

  closeAppError(): void {
    this.showError = false
  }

  doLogin(): void {
    this.routerUtils.login()
  }

  handleNavClick(): void {
    let routeName = 'LandingPage'
    if (this.currentUser.authorized && this.currentUser.authenticated) {
      routeName = 'Welcome'
    }

    this.routerUtils.navigateToPushedRoute(this.$route.name, routeName)
  }

  logout(): void {
    logout().then((user: User) => {
      store.commit('setCurrentUser', user)
      this.routerUtils.navigateToPushedRoute(this.$route.name, 'LandingPage')
    })
  }

  resetTimeout(): void {
    getCurrentUser().then(response => store.commit('setCurrentUser', response))
    this.isTimeoutOpen = false
    this.timeoutDuration = 120
    this.sessionExpirationMinutes = 1
    this.sessionExpirationSeconds = 59
    clearInterval(this.countdownTimer)
  }

  mounted(): void {
    if (this.currentUser.authenticated) {
      //make my calls
    }
    getAppConfig().then((response: AppConfig) => {
      this.registrationURL = response.registrationURL
      this.logoutURL = response.logoutURL
      store.commit('setAppConfig', response)
    })

    getPersonalChecklist().then((response) => {
      const file = new Blob([response], {type: 'application/pdf'})
      const url = URL.createObjectURL(file)
      store.commit('setPersonalChecklistUrl', url)
    }).catch(error => {
      console.log('error loading personal checklist url on app mount', error)
    })

    this.appUtils.loadRequiredData()
  }
}

