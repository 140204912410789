

















































































































































































































import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'

@Component
export default class AppFooter extends Vue {
  @Prop({ type: Boolean })
  isAuthenticated!: boolean

  @Prop({type: Boolean })
  isMobile!: boolean

  footerGaText = 'Great American knows protection.  We offer the same tools we use to protect ourselves to protect our policyholders.  When you\'re secure, we\'re secure.'
}
