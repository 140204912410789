import { DomainData } from '@/models/DomainData'
import { CompanyScore } from '@/models/CompanyScore'

export class UserPreferences {
    companyScore!: CompanyScore
    domain!: DomainData
    id!: string
    isEulaAccepted!: boolean
    lastUpdatedAssessment!: string
    lastViewedInsights!: string
    lastViewedScorecard!: string
    nistMaturityScore!: number
    username!: string
}