import store from '@/store'
import {getNistFunctions, getRecommendations} from '@/service/ScoringService'
import {getUserPreferences} from '@/service/UserService'

export class AppUtils {
    loadRequiredData(doLoadPreferences?: boolean): void {
        const recommendations = store.getters.getRecommendations
        const nistFunctions = store.getters.getCachedNistFunctions
        const userPreferences = store.getters.getUserPreferences
        const user = store.getters.getCurrentUser
        const recommendationsLoading = store.getters.getRecommendationsLoading
        const functionsLoading = store.getters.getFunctionsLoading

        if (user?.authenticated && user?.authorized) {
            if (doLoadPreferences || !userPreferences.id) {
                getUserPreferences().then(userPreferencesResponse => {
                    if (userPreferencesResponse && userPreferencesResponse.username)
                        store.commit('setUserPreferences', userPreferencesResponse)
                }).catch(error => {
                    console.info('Error loading user preferences during navigation', error)
                })
            }
        }

        if (!recommendations?.length && !recommendationsLoading) {
            store.commit('setRequiredDataLoading', true)
            store.commit('setRecommendationsLoading', true)
            getRecommendations().then(recommendationsResponse => {
                store.commit('setRecommendationsLoading', false)
                store.commit('setRecommendations', recommendationsResponse)
                store.commit('setRequiredDataLoading', false)

            }).catch(error => {
                store.commit('setRequiredDataLoading', false)
                store.commit('setRecommendationsLoading', false)
                console.info('Error loading recommendations during navigation', error)
            })
        }

        if (!nistFunctions?.length && !functionsLoading) {
            store.commit('setRequiredDataLoading', true)
            store.commit('setFunctionsLoading', true)
            getNistFunctions().then(nistFunctionsResponse => {
                store.commit('setCachedNistFunctions', nistFunctionsResponse)
                store.commit('setFunctionsLoading', false)
                store.commit('setRequiredDataLoading', false)
            }).catch(error => {
                store.commit('setRequiredDataLoading', false)
                store.commit('setFunctionsLoading', false)
                console.info('Error loading nist functions during navigation', error)
            })
        }
    }
}